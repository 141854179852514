.thanks {
    @media (max-width: 1180px) {
        position: relative;
        padding: 180px 0 250px;
    }
    @media (max-width: 616px) {
        padding: 100px 0 250px;
        position: relative;
        &-title {
            font-size: 36px;
            line-height: 40px;
            padding-bottom: 20px;
            margin-bottom: 30px;
        }
        &-text {
            font-size: 15px;
            line-height: 17px;
        }
    }
}