.general-director {
    @media (max-width: 1180px) {
        padding: 80px 0;
        &__flex {
            margin-top: 40px;
            &-main {
                max-width: 65%;
                height: 430px;
                .title {
                    margin-bottom: 0;
                }
                .text {
                    margin-bottom: 10px;
                }
            }
            &-img {
                max-width: 35%;
            }
        }
        &__block {
            top: -10px;
        }
    }
    @media (max-width: 880px) {
        &__flex {
            &-main {
                max-width: 60%;
                height: 360px;
                .title {
                    font-size: 60px;
                    line-height: 100%;
                }
                .text {
                    font-size: 26px;
                    line-height: 100%;
                }
            }
        }
    }
    @media (max-width: 690px) {
        padding: 50px 0;
        &__flex {
            flex-direction: column;
            &-main {
                max-width: 100%;
                height: auto;
                justify-content: start;
                .title {
                    font-size: 58px;
                    margin-bottom: 10px;
                }
                .text {
                    font-size: 24px;
                    margin-bottom: 20px;
                    br {
                        display: none;
                    }
                }
            }
            &-img {
                max-width: 100%;
                margin: 30px auto;
            }
        }
        &__block {
            position: relative;
            top: 0;
            &-title {
                font-size: 24px;
                margin-bottom: 20px;
            }
            &-flex {
                flex-direction: column;
                .card_block {
                    max-width: 100%;
                    border-top: 1px solid #202020;
                    padding-top: 30px;
                    margin-bottom: 30px;
                    .title {
                        font-size: 26px;
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}