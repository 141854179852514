.partners {
    @media (max-width: 1180px) {
        padding: 80px 0;
        &__flex {
    
            display: none;

            &-partner {
                max-width: 100%;
                margin-bottom: 0;
            }
        }
        &-swiper {
            display: flex;
            padding-bottom: 100px;
            border-bottom: 1px solid #202020;
        }
        .main___navigations {
            display: flex;
        }
    }
    @media (max-width: 616px) {
        padding: 50px 0;
        &-swiper {
            padding-bottom: 50px;
            margin-top: 40px;
        }
    }
}