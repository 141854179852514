.popups-form {
    @media (max-width: 616px) {
        padding: 70px 30px;
    }
}
.popups-works {
    @media (max-width: 616px) {
        padding: 70px 30px;
        &__title {
            font-size: 25px;
            line-height: 100%;
            margin-bottom: 20px;
        }
        &__text {
            font-size: 18px;
            line-height: 100%;
            li {
                padding-bottom: 10px;
            }
        }
    }
}
