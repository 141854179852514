.intro {
    @media (max-width: 1600px) {
        &__facts {
            margin-right: 0;
        }
    }
    @media (max-width: 1500px) {
        .container {
            max-width: 1180px;
        }
        &__main {
            max-width: 40%;
        }
        &__facts {
            max-width: 60%;
        }
    }
    @media (max-width: 1200px) {
        padding: 100px 0 50px;
        background: url(../img/intro/bg_ipad.jpg) no-repeat center / cover;
        .container {
            max-width: 960px;
        }
        &__facts {
            position: relative;
            max-width: 35%;
            display: none;
            &-flex {
                .card_block {
                    max-width: 100%;
                    &:nth-child(2){
                        margin-top: unset;
                    }
                    &-flex {
                        height: auto;
                        margin-bottom: 20px;
                        &:nth-child(2){
                            margin-left: 50px;
                        }
                    }
                }
            }
        }
        .swiper-facts-ipad {
            display: block;
            .swiper-nav {
                position: absolute;
                display: flex;
                right: 0;
                bottom: 0;
                width: 60px;
                height: 10px;
                .swiper-pagination {
                    display: flex;
                    align-items: center;
                }
                  
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    margin: 0 10px 0;
                    background: transparent;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    opacity: 1;
                }
                  
                .swiper-pagination-bullet-active {
                    background: #fff;
                } 
            }
        }
    }
    @media (max-width: 767px) {
        &__facts {
            max-width: 45%;
        }
    }
    @media (max-width: 610px) {
        padding: 200px 0 50px;
        .container {
            max-width: 414px;
        }
        &__main {
            max-width: 100%;
            &-title {
                font-size: 58px;
                line-height: 56px;
                margin-bottom: 20px;
            }
            &-btn {
                max-width: 330px;
            }
        }
        .swiper-facts-ipad {
            display: none;
        }
        &__mobile {
            display: block;
            padding: 20px 0;
        }
        &__facts {
            max-width: 100%;
            &.swiper-facts-mobile {
                display: block;
                .swiper-nav {
                    position: relative;
                    display: flex;
                    width: 25%;
                    height: 40px;
                    margin: 10px auto 0;
                    .swiper-pagination {
                        display: flex;
                        align-items: center;
                        margin: 20px auto 0;
                    }
                    .swiper-pagination-bullet {
                        width: 8px;
                        height: 8px;
                        margin: 0 5px 0;
                        background: transparent;
                        border: 1px solid #000;
                        border-radius: 50%;
                        opacity: 1;
                    }
                      
                    .swiper-pagination-bullet-active {
                        background: #000;
                    } 
                }
            }
            &-flex {
                .card_block {
                    &-flex {
                        height: auto;
                        margin-bottom: 0;
                        &:nth-child(2){
                            margin-left: unset;
                        }
                        .round {
                            background: #202020;
                            margin-bottom: 10px;
                        }
                        .title {
                            font-size: 58px;
                            line-height: 56px;
                            color: #202020;
                        }
                        .text {
                            font-size: 15px;
                            line-height: 17px;
                            color: #202020;
                        }
                    }
                }
            }
        }

    }
    @media (max-width: 380px) {
        padding: 400px 0 50px;
        background: url(../img/intro/bg_mobile.jpg) no-repeat center / cover;
    }
}