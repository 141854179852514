/**********/
/* Common */

@import 'form/_responsive.scss';

/*************/
/* Blocks */

@import 'header/_responsive.scss';
@import 'intro/_responsive.scss';
@import 'about/_responsive.scss';
@import 'works/_responsive.scss';
@import 'cases/_responsive.scss';
@import 'partners/_responsive.scss';
@import 'general-director/_responsive.scss';
@import 'teams/_responsive.scss';
@import 'outro/_responsive.scss';
@import 'footer/_responsive.scss';

/*************/
/* Popups */

@import 'popups/_responsive.scss';
@import 'thanks/_responsive.scss';
