/**********/

/* Common */

/*************/

/* Blocks */

/*************/

/* Popups */

@media (max-width: 1600px) {
  .intro__facts {
    margin-right: 0;
  }
}

@media (max-width: 1500px) {
  .header.fixed .header__flex {
    padding: 15px 20px;
  }

  .header__flex {
    padding: 20px 20px;
  }

  .header__flex-logo {
    width: auto;
    margin-right: 10px;
  }

  .header__flex-logo img {
    max-width: 150px;
  }

  .header__flex-menu {
    margin: 0 20px 0 auto;
  }

  .intro .container {
    max-width: 1180px;
  }

  .intro__main {
    max-width: 40%;
  }

  .intro__facts {
    max-width: 60%;
  }
}

@media (max-width: 1280px) {
  .header__flex-phone .phone {
    font-size: 24px;
    line-height: 32px;
  }

  .header__flex-phone .phone_mob {
    display: none;
  }

  .header__flex-links {
    font-size: 20px;
  }

  .outro {
    padding: 80px 0 10px;
  }

  .outro__flex-contact {
    height: 340px;
    border-bottom: none;
  }

  .outro__flex-contact .title {
    font-size: 75px;
    padding-left: 0px;
  }

  .outro__flex-contact .phone::before {
    top: -60px;
  }

  .outro__flex-adress {
    min-height: 355px;
    padding: 40px 0 20px;
  }

  .outro__flex-adress .adress {
    font-size: 28px;
    line-height: 100%;
  }

  .outro__flex-adress .title {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .intro {
    padding: 100px 0 50px;
    background: url(../img/intro/bg_ipad.jpg) no-repeat center/cover;
  }

  .intro .container {
    max-width: 960px;
  }

  .intro__facts {
    position: relative;
    max-width: 35%;
    display: none;
  }

  .intro__facts-flex .card_block {
    max-width: 100%;
  }

  .intro__facts-flex .card_block:nth-child(2) {
    margin-top: unset;
  }

  .intro__facts-flex .card_block-flex {
    height: auto;
    margin-bottom: 20px;
  }

  .intro__facts-flex .card_block-flex:nth-child(2) {
    margin-left: 50px;
  }

  .intro .swiper-facts-ipad {
    display: block;
  }

  .intro .swiper-facts-ipad .swiper-nav {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 10px;
  }

  .intro .swiper-facts-ipad .swiper-nav .swiper-pagination {
    display: flex;
    align-items: center;
  }

  .intro .swiper-facts-ipad .swiper-nav .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 10px 0;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    opacity: 1;
  }

  .intro .swiper-facts-ipad .swiper-nav .swiper-pagination-bullet-active {
    background: #fff;
  }
}

@media (max-width: 1180px) {
  .about__flex {
    flex-direction: column;
  }

  .about__flex-about {
    max-width: 100%;
    height: auto;
    justify-content: start;
    border-bottom: none;
    padding: 60px 0 50px;
  }

  .about__flex-about .text br {
    display: none;
  }

  .about__flex-cards {
    max-width: 100%;
  }

  .about__flex-cards .card_block {
    position: relative;
    max-width: 48%;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #202020;
    padding: 75px 20px 60px;
  }

  .about__flex-cards .card_block .round {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 24px;
    height: 24px;
    background: #202020;
    border-radius: 50%;
  }

  .about__flex-cards .card_block .title {
    font-size: 80px;
    line-height: 70px;
    color: #9A17C6;
  }

  .about__flex-cards .card_block .text {
    font-size: 36px;
    line-height: 100%;
  }

  .cases {
    padding: 80px 0;
  }

  .cases__flex-team .card_block {
    height: 100px;
  }

  .cases__flex-team .text {
    font-size: 18px;
  }

  .partners {
    padding: 80px 0;
  }

  .partners__flex {
    display: none;
  }

  .partners__flex-partner {
    max-width: 100%;
    margin-bottom: 0;
  }

  .partners-swiper {
    display: flex;
    padding-bottom: 100px;
    border-bottom: 1px solid #202020;
  }

  .partners .main___navigations {
    display: flex;
  }

  .general-director {
    padding: 80px 0;
  }

  .general-director__flex {
    margin-top: 40px;
  }

  .general-director__flex-main {
    max-width: 65%;
    height: 430px;
  }

  .general-director__flex-main .title {
    margin-bottom: 0;
  }

  .general-director__flex-main .text {
    margin-bottom: 10px;
  }

  .general-director__flex-img {
    max-width: 35%;
  }

  .general-director__block {
    top: -10px;
  }

  .teams {
    padding: 80px 0;
  }

  .footer__block {
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid #202020;
    padding-top: 30px;
  }

  .footer__block-menu {
    max-width: 100%;
  }

  .footer__block-social {
    max-width: 25%;
    margin-top: 20px;
  }

  .footer__flex {
    position: relative;
    top: -10px;
    margin-top: 0;
  }

  .footer__flex-links {
    margin-right: 20px;
  }

  .thanks {
    position: relative;
    padding: 180px 0 250px;
  }
}

@media (max-width: 1040px) {
  .works {
    padding: 80px 0;
  }

  .works__flex {
    display: flex;
  }

  .works__flex.works-swiper {
    display: none;
  }

  .works__flex-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }

  .works__flex-team .grid-1 {
    height: 270px;
    grid-area: unset;
    justify-content: start;
  }

  .works__flex-team .grid-2 {
    height: 270px;
    grid-area: unset;
    justify-content: start;
  }

  .works__flex-team .grid-3 {
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .works__flex-team .grid-4 {
    height: 270px;
    grid-area: unset;
    justify-content: start;
  }

  .works__flex-team .card_block {
    max-width: 49%;
    width: 100%;
    padding: 60px 20px;
    margin-bottom: 30px;
  }

  .works__flex-team .card_block[data-more-hidden] {
    display: none;
  }

  .works__flex-more {
    display: block;
  }

  .works__flex-more .name {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 25px;
    color: #202020;
  }

  .works__flex-more .name span {
    margin-right: 20px;
  }

  .works .main___navigations {
    display: none;
  }
}

@media (max-width: 900px) {
  .header__flex-logo {
    max-width: 125px;
  }

  .header__flex-menu {
    display: none;
  }

  .header__flex-phone {
    margin: 0 0 0 auto;
  }

  .header__flex-phone .phone {
    display: none;
  }

  .header__flex-phone .phone_mob {
    display: block;
  }

  .header__flex-links {
    display: none;
  }

  .header__flex-burger {
    display: block;
    margin-left: 20px;
  }

  .outro__flex-contact .title {
    font-size: 55px;
    line-height: 100%;
  }

  .outro__flex-adress {
    width: 100%;
    min-height: auto;
    margin-right: 20px;
    padding: 30px;
    border-bottom: none;
  }

  .outro__flex-adress:not(:last-child) {
    margin-right: 20px;
  }

  .outro__flex-adress .adress {
    font-size: 32px;
  }

  .outro__flex-adress .adress br {
    display: none;
  }
}

@media (max-width: 880px) {
  .cases__flex-team .card_block {
    height: 140px;
  }

  .general-director__flex-main {
    max-width: 60%;
    height: 360px;
  }

  .general-director__flex-main .title {
    font-size: 60px;
    line-height: 100%;
  }

  .general-director__flex-main .text {
    font-size: 26px;
    line-height: 100%;
  }
}

@media (max-width: 767px) {
  .intro__facts {
    max-width: 45%;
  }

  .cases {
    padding: 50px 0;
  }

  .cases__flex {
    padding-right: 20px;
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .cases__flex-team .name {
    font-size: 24px;
  }

  .cases__flex-team .name br {
    display: none;
  }

  .cases__flex-team .card_block {
    height: auto;
  }

  .cases__flex-team .title {
    position: relative;
    top: -20px;
    font-size: 24px;
  }

  .cases__flex-team .text {
    font-size: 15px;
  }

  .cases__flex-team .result {
    font-size: 24px;
  }

  .cases__flex-team .result span {
    font-size: 15px;
  }

  .teams {
    padding: 50px 0;
  }

  .teams__flex {
    margin-top: 20px;
    padding-bottom: 50px;
    margin-bottom: 20px;
  }

  .teams__flex-team .name {
    font-size: 24px;
  }

  .teams__flex-team .name br {
    display: none;
  }

  .teams__flex-team .title {
    font-size: 20px;
  }

  .teams__flex-team .text {
    font-size: 15px;
  }

  .outro__flex {
    flex-direction: column;
    align-items: start;
    padding: 50px 0 20px;
  }

  .outro__flex-contact {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .outro__flex-contact .title {
    font-size: 55px;
  }

  .outro__flex-contact .phone::before {
    top: -50px;
  }

  .outro__flex-adress {
    max-width: 100%;
    height: auto;
    border-top: none;
  }

  .outro__flex-adress .adress {
    font-size: 30px;
  }

  .outro__flex-adress .adress br {
    display: none;
  }

  .footer__block {
    flex-direction: column;
    align-items: start;
  }

  .footer__block-menu {
    flex-direction: column;
  }

  .footer__block-menu .logo_footer {
    margin-bottom: 30px;
  }

  .footer__block-menu .menu {
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 17px;
    margin: 0;
  }

  .footer__block-menu .menu li {
    margin: 0 15px 20px 0;
  }

  .footer__block-social {
    max-width: 100%;
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .footer__block-social .social {
    margin-right: 30px;
  }

  .footer__flex {
    flex-direction: column;
    align-items: start;
    top: 0;
  }

  .footer__flex-links {
    margin-bottom: 20px;
  }
}

@media (max-width: 690px) {
  .general-director {
    padding: 50px 0;
  }

  .general-director__flex {
    flex-direction: column;
  }

  .general-director__flex-main {
    max-width: 100%;
    height: auto;
    justify-content: start;
  }

  .general-director__flex-main .title {
    font-size: 58px;
    margin-bottom: 10px;
  }

  .general-director__flex-main .text {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .general-director__flex-main .text br {
    display: none;
  }

  .general-director__flex-img {
    max-width: 100%;
    margin: 30px auto;
  }

  .general-director__block {
    position: relative;
    top: 0;
  }

  .general-director__block-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .general-director__block-flex {
    flex-direction: column;
  }

  .general-director__block-flex .card_block {
    max-width: 100%;
    border-top: 1px solid #202020;
    padding-top: 30px;
    margin-bottom: 30px;
  }

  .general-director__block-flex .card_block .title {
    font-size: 26px;
  }

  .general-director__block-flex .card_block .title br {
    display: none;
  }
}

@media (max-width: 616px) {
  .form__title {
    font-size: 24px;
  }

  .form__title span {
    font-size: 15px;
  }

  .header__flex-logo {
    max-width: 90px;
  }

  .header__flex-links {
    max-width: 160px;
    margin: 0 15px;
    font-size: 16px;
    line-height: normal;
  }

  .header__flex-burger .links img {
    max-width: 130%;
    width: 130%;
  }

  .about {
    padding: 60px 0;
  }

  .about__flex-about {
    padding: 40px 0 50px;
  }

  .about__flex-about .main_text {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .about__flex-about .text {
    font-size: 16px;
  }

  .about__flex-cards .card_block {
    max-width: 100%;
    padding: 60px 40px 50px;
  }

  .about__flex-cards .card_block .title {
    font-size: 58px;
    line-height: 56px;
  }

  .about__flex-cards .card_block .text {
    font-size: 24px;
  }

  .works {
    padding: 50px 0;
  }

  .works__flex-team .grid-1 {
    height: 200px;
  }

  .works__flex-team .grid-2 {
    height: 200px;
  }

  .works__flex-team .grid-3 {
    height: 200px;
  }

  .works__flex-team .grid-4 {
    height: 200px;
  }

  .works__flex-team .card_block {
    max-width: 100%;
    padding: 50px 20px;
    margin-bottom: 15px;
  }

  .works__flex-team .card_block .title {
    font-size: 24px;
    line-height: 100%;
  }

  .works__flex-team .card_block-links {
    bottom: 30px;
  }

  .works__flex-team .card_block-links .link {
    font-size: 15px;
    line-height: 17px;
  }

  .works__flex-more .name {
    font-size: 15px;
    line-height: 17px;
  }

  .partners {
    padding: 50px 0;
  }

  .partners-swiper {
    padding-bottom: 50px;
    margin-top: 40px;
  }

  .outro {
    padding: 30px 0 10px;
  }

  .outro__flex-contact .title {
    font-size: 25px;
  }

  .outro__flex-adress {
    margin-right: 0;
  }

  .outro__flex-adress .adress {
    font-size: 25px;
  }

  .popups-form {
    padding: 70px 30px;
  }

  .popups-works {
    padding: 70px 30px;
  }

  .popups-works__title {
    font-size: 25px;
    line-height: 100%;
    margin-bottom: 20px;
  }

  .popups-works__text {
    font-size: 18px;
    line-height: 100%;
  }

  .popups-works__text li {
    padding-bottom: 10px;
  }

  .thanks {
    padding: 100px 0 250px;
    position: relative;
  }

  .thanks-title {
    font-size: 36px;
    line-height: 40px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .thanks-text {
    font-size: 15px;
    line-height: 17px;
  }
}

@media (max-width: 610px) {
  .intro {
    padding: 200px 0 50px;
  }

  .intro .container {
    max-width: 414px;
  }

  .intro__main {
    max-width: 100%;
  }

  .intro__main-title {
    font-size: 58px;
    line-height: 56px;
    margin-bottom: 20px;
  }

  .intro__main-btn {
    max-width: 330px;
  }

  .intro .swiper-facts-ipad {
    display: none;
  }

  .intro__mobile {
    display: block;
    padding: 20px 0;
  }

  .intro__facts {
    max-width: 100%;
  }

  .intro__facts.swiper-facts-mobile {
    display: block;
  }

  .intro__facts.swiper-facts-mobile .swiper-nav {
    position: relative;
    display: flex;
    width: 25%;
    height: 40px;
    margin: 10px auto 0;
  }

  .intro__facts.swiper-facts-mobile .swiper-nav .swiper-pagination {
    display: flex;
    align-items: center;
    margin: 20px auto 0;
  }

  .intro__facts.swiper-facts-mobile .swiper-nav .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 5px 0;
    background: transparent;
    border: 1px solid #000;
    border-radius: 50%;
    opacity: 1;
  }

  .intro__facts.swiper-facts-mobile .swiper-nav .swiper-pagination-bullet-active {
    background: #000;
  }

  .intro__facts-flex .card_block-flex {
    height: auto;
    margin-bottom: 0;
  }

  .intro__facts-flex .card_block-flex:nth-child(2) {
    margin-left: unset;
  }

  .intro__facts-flex .card_block-flex .round {
    background: #202020;
    margin-bottom: 10px;
  }

  .intro__facts-flex .card_block-flex .title {
    font-size: 58px;
    line-height: 56px;
    color: #202020;
  }

  .intro__facts-flex .card_block-flex .text {
    font-size: 15px;
    line-height: 17px;
    color: #202020;
  }
}

@media (max-width: 380px) {
  .header__flex-links {
    max-width: 110px;
  }

  .intro {
    padding: 400px 0 50px;
    background: url(../img/intro/bg_mobile.jpg) no-repeat center/cover;
  }
}