.header {
    @media (max-width: 1500px) {
        &.fixed {
            .header__flex {
                padding: 15px 20px;
            }
        }
        &__flex {
            padding: 20px 20px;
            &-logo {
                width: auto;
                margin-right: 10px;
                img {
                    max-width: 150px;
                }
            }
            &-menu {
                margin: 0 20px 0 auto;
            }
        }
    }
    @media (max-width: 1280px) {
        &__flex {
            &-phone {
                .phone {
                    font-size: 24px;
                    line-height: 32px;
                }
                .phone_mob {
                    display: none;
                }
            }
            &-links {
                font-size: 20px;
            }
        }
    }
    @media (max-width: 900px) {
        &__flex {
            &-logo {
                max-width: 125px;
            }
            &-menu {
                display: none;
            }
            &-phone {
                margin: 0 0 0 auto;
                .phone {
                    display: none;
                }
                .phone_mob {
                    display: block;
                }
            }
            &-links {
                display: none;
            }
            &-burger {
                display: block;
                margin-left: 20px;
            }
        }
    }
    @media (max-width: 616px) {
        &__flex {
            &-logo {
                max-width: 90px;
            }
            &-links {
                max-width: 160px;
                margin: 0 15px;
                font-size: 16px;
                line-height: normal;
            }
            &-burger {
                .links {
                    img {
                        max-width: 130%;
                        width: 130%;
                    }
                }
            }
        }
    }
    @media (max-width: 380px) {
        &__flex {
            &-links {
                max-width: 110px;
            }
        }
    }
}
