.cases {
    @media (max-width: 1180px) {
        padding: 80px 0;
        &__flex {
            &-team {
                .card_block {
                    height: 100px;
                }
                .text {
                    font-size: 18px;
                }
            }
        }
    }
    @media (max-width: 880px) {
        &__flex {
            &-team {
                .card_block {
                    height: 140px;
                }
            }
        }
    }
    @media (max-width: 767px) {
        padding: 50px 0;
        &__flex {
            padding-right: 20px;
            margin-top: 20px;
            padding-bottom: 0;
            margin-bottom: 10px;
            &-team {
                .name {
                    font-size: 24px;
                    br {
                        display: none;
                    }
                }
                .card_block {
                    height: auto;
                }
                .title {
                    position: relative;
                    top: -20px;
                    font-size: 24px;
                }
                .text {
                    font-size: 15px;
                }
                .result {
                    font-size: 24px;
                    span {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}