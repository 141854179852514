.works {
@media (max-width: 1040px) {
    padding: 80px 0;
    &__flex {
        display: flex;
        &.works-swiper {
            display: none;
        }
        &-team {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            grid-column-gap: unset;
            grid-row-gap: unset;
            .grid-1 { 
                height: 270px;
                grid-area: unset; 
                justify-content: start;
            }
            .grid-2 { 
                height: 270px;
                grid-area: unset; 
                justify-content: start;
            }
            .grid-3 { 
                height: 270px;
                display: flex;
                flex-direction: column;
                justify-content: start;
            }
            .grid-4 {
                height: 270px;
                grid-area: unset; 
                justify-content: start;
            }

            .card_block {
                max-width: 49%;
                width: 100%;
                padding: 60px 20px;
                margin-bottom: 30px;
                &[data-more-hidden] {
                    display: none;
                }
            }
        }
        &-more {
            display: block;
            .name {
                display: flex;
                align-items: center;
                font-size: 22px;
                line-height: 25px;
                color: #202020;
                span {
                    margin-right: 20px;
                }

            }
        }
    }
    .main___navigations {
        display: none;
    }
}
@media (max-width: 616px) {
    padding: 50px 0;
    &__flex {
        &-team {
            
            .grid-1 { 
                height: 200px;
            }
            .grid-2 { 
                height: 200px;
            }
            .grid-3 { 
                height: 200px;
            }
            .grid-4 {
                height: 200px;
            }
            .card_block {
                max-width: 100%;
                padding: 50px 20px;
                margin-bottom: 15px;
                .title {
                    font-size: 24px;
                    line-height: 100%;
                }
                &-links {
                    bottom: 30px;
                    .link {
                        font-size: 15px;
                        line-height: 17px;
                    }
                }
            }
        }
        &-more {
            .name {
                font-size: 15px;
                line-height: 17px;
            }
        }
    }
}
}