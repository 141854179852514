.footer {
    @media (max-width: 1180px) {
        &__block {
            flex-direction: column;
            align-items: flex-end;
            border-top: 1px solid #202020;
            padding-top: 30px;
            &-menu {
                max-width: 100%;
            }
            &-social {
                max-width: 25%;
                margin-top: 20px;
            }
        }
        &__flex {
            position: relative;
            top: -10px;
            margin-top: 0;
            &-links {
                margin-right: 20px;
            }
        }
    }

    @media (max-width: 767px) {
        &__block {
            flex-direction: column;
            align-items: start;
            &-menu {
                flex-direction: column;
                .logo_footer {
                    margin-bottom: 30px;
                }
                .menu {
                    flex-wrap: wrap;
                    font-size: 16px;
                    line-height: 17px;
                    margin: 0;
                    li {
                        margin: 0 15px 20px 0;
                    }
                }
            }
            &-social {
                max-width: 100%;
                justify-content: start;
                margin-top: 10px;
                margin-bottom: 50px;
                .social {
                    margin-right: 30px;
                }
            }
        }
    
        &__flex {
            flex-direction: column;
            align-items: start;
            top: 0;
            &-links {
                margin-bottom: 20px;
            }
        }
    }
}