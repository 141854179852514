.teams {
    @media (max-width: 1180px) {
        padding: 80px 0;
    }
    @media (max-width: 767px) {
        padding: 50px 0;
        &__flex {
            margin-top: 20px;
            padding-bottom: 50px;
            margin-bottom: 20px;
            &-team {
                .name {
                    font-size: 24px;
                    br {
                        display: none;
                    }
                }
                .title {
                    font-size: 20px;
                }
                .text {
                    font-size: 15px;
                }
            }
        }
    }
}