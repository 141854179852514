.outro {
    @media (max-width: 1280px) {
        padding: 80px 0 10px;
        &__flex {
            &-contact {
                height: 340px;
                border-bottom: none;
                .title {
                    font-size: 75px;
                    padding-left: 0px;
                }
                .phone {
                    &::before{
                        top: -60px;
                    }
                }
            }
            &-adress {
                min-height: 355px;
                padding: 40px 0 20px;
                .adress {
                    font-size: 28px;
                    line-height: 100%;
                }
                .title {
                    font-size: 24px;
                }
            }
        }
    }
    @media (max-width: 900px) {
        &__flex {
            &-contact {
                .title {
                    font-size: 55px;
                    line-height: 100%;
                }
            }
            &-adress {
                width: 100%;
                min-height: auto;
                margin-right: 20px;
                padding: 30px;
                border-bottom: none;
                &:not(:last-child) {
                    margin-right: 20px;
                }
                .adress {
                    font-size: 32px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        &__flex {
            flex-direction: column;
            align-items: start;
            padding: 50px 0 20px;
            &-contact {
                max-width: 100%;
                height: auto;
                margin-bottom: 30px;
                .title {
                    font-size: 55px;
                }
                .phone {
                    &::before{
                        top: -50px;
                    }
                }
            }
            &-adress {
                max-width: 100%;
                height: auto;
                border-top: none;
                .adress {
                    font-size: 30px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    @media (max-width: 616px) {
        padding: 30px 0 10px;
        &__flex {
            &-contact {
                .title {
                    font-size: 25px;
                }
            }
            &-adress {
                margin-right: 0;
                .adress {
                    font-size: 25px;
                }
            }
        }
    }
}
