.about {
    @media (max-width: 1180px) {
        &__flex {
            flex-direction: column;
            &-about {
                max-width: 100%;
                height: auto;
                justify-content: start;
                border-bottom: none;
                padding: 60px 0 50px;
                .text {
                    br {
                        display: none;
                    }
                }
            }
            &-cards {
                max-width: 100%;
                .card_block {
                    position: relative;
                    max-width: 48%;
                    width: 100%;
                    margin-bottom: 20px;
                    border: 1px solid #202020;
                    padding: 75px 20px 60px;
                    .round {
                        position: absolute;
                        top: 10px;
                        right: 20px;
                        width: 24px;
                        height: 24px;
                        background: #202020;
                        border-radius: 50%;
                    }
                    .title {
                        font-size: 80px;
                        line-height: 70px;
                        color: #9A17C6;
                    }
                    .text {
                        font-size: 36px;
                        line-height: 100%;
                    }
                }
            }
        }
    }
    @media (max-width: 616px) {
        padding: 60px 0;
        &__flex {
            &-about {
                padding: 40px 0 50px;
                .main_text {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .text {
                    font-size: 16px;
                }
            }
            &-cards {
                .card_block {
                    max-width: 100%;
                    padding: 60px 40px 50px;
                    .title {
                        font-size: 58px;
                        line-height: 56px;
                    }
                    .text {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}